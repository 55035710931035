import './Wifi.css';
const Wifi = () => {
    return (
        <div className="wifi-symbol">
            <div className="wifi-circle first"></div>
            <div className="wifi-circle second"></div>
            <div className="wifi-circle third"></div>	
            <div className="wifi-circle fourth"></div>
        </div>
    );
}
export default Wifi;