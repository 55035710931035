import './Title.css';
const Title = (props) => {
    let sharedProp = ["Title"];
    if(props.products || props.clients){
        sharedProp.push("TitleRight")
    }else if(props.about){
        sharedProp.push("aboutTitle")
    }else if(props.services){
        sharedProp.push("ServicesTitle")
    } else if(props.team){
        sharedProp.push("teamTitle")
    }else if(props.contact){
        sharedProp.push("contactTitle")
    }else{
        sharedProp = ["Title"]
    }
    return (

            <div className = {sharedProp.join(" ")}>
                <img src={props.img} alt={props.imgTitle}/>
                <h2>{props.title}</h2>            
            </div>

    );
}
export default Title;