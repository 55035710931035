import * as ActionTypes from '../actions/types';

const INIT_STATE = {
    services: null
};

const servicesReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_SERVICES:
            return {...state, services: action.data.data};
        default:
            return state;
    }
};
export default servicesReducer;