import {GET_SERVICES} from './types';
import {Backend} from '../services/Backend';

export const getServices = (pageNumber) => {
    return async (dispatch) => {
        const name = `/services`;
        await Backend.getPage(name, pageNumber).then(response => {
            dispatch({
                type: GET_SERVICES,
                data: response.data
            });
        });
    };
};
