import './AboutText.css';
const AboutText = ({details}) => {
    return (
        <div className="about-text col-xl-6 mb-5">
            <div 
                className="aboutText" 
                dangerouslySetInnerHTML={{__html: details}}/>
        </div>
    );
}

export default AboutText;