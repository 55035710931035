import React, { Component } from 'react';
import {connect} from 'react-redux';
import * as actions from '../../../actions/index';
import Info from '../../../components/ContactInfo/Info';
class ContactInfo extends Component {
    componentDidMount(){
        this.props.ongetAboutInfo();
    }
    render() {
        let info;
        if(this.props.about){
            info =  <Info contactInfo={this.props.about.data}/>
        }
        return (
            <div className="col-lg-6 mb-5 mb-lg-0">
                {info}
            </div>
        );
    }
}
const mapStateToProps = state => {
        return{
            about: state.about.aboutInfo
        };
    }
const mapDispatchToProps = dispatch => {
    return {
        ongetAboutInfo: () => dispatch(actions.getAboutInfo()), 
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);