import * as axios from 'axios';
import {BASE_URL} from '../config';
import strings from '../strings';
axios.defaults.baseURL = BASE_URL;

const lang = localStorage.getItem("lang") || "en"; // set the headers for each http request made with axios
axios.defaults.headers.common["Language"] = lang; 
strings.setLanguage(lang) // tell localization to change the language
document.documentElement.lang = lang;// to add some style for the arabic language
export const Backend = {
// get about-us info 
    getAboutInfo() {
        return axios.get(`/who-we-are`)
            .then(function(response) {
                return response;
        })
            .catch(function(err) {
            return err;
        });
    },

// post form-data (contact us)
    contactInfo(contactData) {
        return axios.post(`/contact-us`, contactData)
            .then(function(response) {
                return response;
        })
            .catch(function(err) {
            return err;
        });
    },


// get page 
    getPage(name, pageNumber = 1) {
        return axios.get(`${name}?page=${pageNumber}`)
            .then(function(response) {
                return response;
        })
            .catch(function(err) {
            return err;
        });
    },
};
