import './Service.css';
import { IMAGE_URL } from '../../config';
const Service = (props) => {
    const {image, name, description} = props;
    return (
        <div className="col-md-6 col-lg-4 mb-3 mb-md-5 mb-lg-0 service px-5">
            <div>
                <div className="service-img mb-3">
                    <img src={IMAGE_URL+image} alt={name}/>
                </div>
                <div className="service-info">
                    <h4>{name}</h4> 
                    <p>{description}</p>
                </div>
            </div>
        </div>
    );
}
export default Service;