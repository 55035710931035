import './AboutBg.css';
import { IMAGE_URL } from '../../../config';
import Wifi from './Wifi-img/Wifi';
const AboutBg = (props) => {
    return (
        <div className="col-xl-6 about-bg px-md-5 px-xl-0 mt-5 mt-xl-0">
            <Wifi/>
            <img 
                src={IMAGE_URL + props.image} 
                alt="about bg"
                className="mb-5"/> 
        </div>
    );
}

export default AboutBg;