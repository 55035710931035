import {GET_TEAM} from './types';
import {Backend} from '../services/Backend';

export const getTeam = (pageNumber) => {
    return async (dispatch) => {
        const name = `/team`;
        await Backend.getPage(name, pageNumber).then(response => {
            dispatch({
                type: GET_TEAM,
                data: response.data
            });
        });
    };
};