import './HeroBanner.css';
import appVideo from '../../assets/videos/video.mp4';
import Typewriter from 'typewriter-effect';
import img1 from '../../assets/images/img1.png';
import strings from "../../strings";
const HeroBanner = () => {
    return (
        <div className="HeroBanner">
            <video autoPlay="autoplay" loop="loop" muted className="Video">
                <source src={appVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video> 
            <div className="Content">
                <div className="TextContainer">
                    <p>{strings.heroBanner.first_p}</p>
                    <Typewriter
                        className="Typewriter"
                        options={{
                        strings: [strings.heroBanner.second_p],
                        autoStart: true,
                        loop: true,
                        delay: 200,
                    }}/> 
                </div>
                <div className="ImgContainer">
                    <img src={img1} alt="img1"/>
                </div>
            </div>
        </div>
    );
}

export default HeroBanner;