import React, { Component } from "react";
import "./Form.css";
import * as actions from '../../../actions/index';
import {connect} from 'react-redux';
import strings from '../../../strings';

/* 1-(your name) @ 2-(domain) . 3-(extension) . 4-(again) => ex: the boss@yahoo.co.uk
1-any letters, numbers, dots and or hyphens
2-any letters, numbers, and or hyphens
3-any letters (with the range from 2 to 8)
4-dot then any letters (with the range from 2 to 8)*/    
const emailRegex = RegExp(
    /^([a-z\d.-]+)@([a-z\d-]+)\.([a-z]{2,8})(\.[a-z]{2,8})?$/
);

const formValid = ({ formErrors, ...rest }) => {
    let valid = true;
  // validate form errors being empty
    Object.values(formErrors).forEach(val => {
        val.length > 0 && (valid = false);
    });

  // validate the form was filled out
    Object.values(rest).forEach(val => {
        val === null && (valid = false);
    });

    return valid;
};

class Form extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: null,
            email: null,
            phone: null,
            message: null,
            formErrors: {
                userName: "",
                email: "",
                phone: "",
                message: ""
            },
            successMsg : "",
            
        };
    }

    handleSubmit = e => {
        e.preventDefault();
        e.target.reset(); // reset form input after sending it 
        if (formValid(this.state)) {
            const contactData= {
                name: this.state.userName,
                email: this.state.email,
                mobile_number: this.state.phone,
                message: this.state.message
            }
            this.props.oncontactInfo(contactData);
            this.setState({successMsg: strings.formMsgs.valid});
            setTimeout(() => { // to remove the ms after appearing &  reset form input after sending it 
                this.setState({
                    successMsg: "", 
                    userName: null, 
                    email: null, 
                    phone: null, 
                    message: null
                })
            }, 3000);
        } else {
            this.setState({invalidForm: strings.formMsgs.invalid})
            setTimeout(() => {  
                this.setState({invalidForm: ""})
            }, 3000);
        //console.error("FORM INVALID - DISPLAY ERROR MESSAGE");
        }
    };

    handleChange = e => {
        e.preventDefault();
        const { name, value } = e.target;
        let formErrors = { ...this.state.formErrors };
        switch (name) {
            case "userName":
                formErrors.userName =
                value.length < 3 ? strings.formMsgs.name : "";
                break;
            case "email":
                formErrors.email = emailRegex.test(value)
                ? ""
                : strings.formMsgs.email;
                break; 

            case "phone":
                formErrors.phone =
                value.length < 10 ? strings.formMsgs.phone : "";
                break;                           
            case "message":
                formErrors.message =
                value.length < 3 ? strings.formMsgs.message : "";
                break;
            default:
                break;
        }
        this.setState({ formErrors, [name]: value });
    };

    render() {
        if(this.props.loading){
            console.log(this.props.loading)
        }
        const { formErrors } = this.state;
        return (
            <div className="col-lg-6">
                <h1 className="form-header">{strings.contact.heading}</h1>
                <form onSubmit={this.handleSubmit} noValidate>
                    {/* --------------  1st row  ---------------- */}
                    <div className="row no-gutters">
                        {/* start name  */}
                        <div className="col-lg-6 ">
                            <div className="field-container input-margin">
                                <input
                                    className={formErrors.userName.length > 0 ? "error" : null}
                                    placeholder={strings.contact.name}
                                    type="text"
                                    name="userName"
                                    noValidate
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                />
                                {formErrors.userName.length > 0 && (
                                    <span className="errorMessage">{formErrors.userName}</span>
                                )} 
                            </div>
                        </div>
                        {/* end name */}
                    
                        {/* start phone */}
                        <div className="col-lg-6">
                            <div className="field-container">
                                <input
                                    className={formErrors.phone.length > 0 ? "error" : null}
                                    placeholder={strings.contact.phone}
                                    type="text"
                                    name="phone"
                                    noValidate
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                />    
                                {formErrors.phone.length > 0 && (
                                    <span className="errorMessage">{formErrors.phone}</span>
                                )}
                            </div>
                        </div>
                        {/* end phone */}
                    </div>

                    {/* --------------  2nd row  ---------------- */}
                    <div className="row">
                        {/* start email */}
                        <div className="col">
                            <div className="field-container">
                                <input
                                    className={formErrors.email.length > 0 ? "error" : null}
                                    placeholder={strings.contact.email}
                                    type="email"
                                    name="email"
                                    noValidate
                                    onChange={this.handleChange}
                                    autoComplete="off"
                                    />
                                {formErrors.email.length > 0 && (
                                    <span className="errorMessage">{formErrors.email}</span>
                                )}
                            </div>
                        </div>
                        {/* end email */}   
                    </div>

                    {/* --------------- 3rd row ---------------- */}
                    <div className="row">
                        {/* start message */}
                        <div className="col">
                            <div className="field-container">
                                <textarea 
                                    className={formErrors.message.length > 0 ? "error" : null}
                                    placeholder={strings.contact.message}
                                    type="text"
                                    name="message"
                                    noValidate
                                    onChange={this.handleChange}
                                    autoComplete="off"/>
                                    {formErrors.message.length > 0 && (
                                    <span className="errorMessage">{formErrors.message}</span>
                                    )} 
                            </div>   
                        </div>
                        {/* end message */}
                    </div>

                    {/* ---------------- 4th row --------------- */}
                    <div className="row">
                        <div className="col d-md-flex justify-content-md-center align-items-md-center flex-column">
                            <button type="submit">{strings.contact.send}</button>
                            <p className="invalid-form">{this.state.invalidForm}</p>
                            <p className="valid-form">{this.state.successMsg}</p>
                        </div>
                    </div>
                </form>     
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => {
    return {
        oncontactInfo: (contactData) => dispatch(actions.contactInfo(contactData))
    };
}
export default connect(null, mapDispatchToProps)(Form);