import React, { Component } from 'react';
import './AboutUs.css';
import Title from "../../components/shared/Title/Title";
import img from "../../assets/images/purple-bg.png"
import AboutText from "../../components/About/AboutText/AboutText";
import AboutBg from "../../components/About/AboutBg/AboutBg";
import strings from '../../strings';
import {connect} from 'react-redux';
import * as actions from '../../actions/index';
class AboutUs extends Component {
    componentDidMount(){
        this.props.ongetAboutInfo();
    }
    render() {
        let info;
        if(this.props.about){
            info =  (
                <>
                    <AboutText details={this.props.about.data.details}/>
                    <AboutBg image={this.props.about.data.image}/>
                </>
            )
        }
        return (
            <div id="about-us" style={{backgroundColor: '#4B3494'}}>
                <div className="container-fluid">
                    <Title img={img} title={strings.heading.about} about/>
                    <div className="about-dimension padding-x">
                        <div className="row">
                            {info}
                        </div>
                    </div>
                </div>
            </div> 
        );
    }
}

const mapStateToProps = state => {
    return{
        about: state.about.aboutInfo
    };
}
const mapDispatchToProps = dispatch => {
return {
    ongetAboutInfo: () => dispatch(actions.getAboutInfo()), 
};
}
export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
