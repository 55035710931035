import React from 'react';
import './ProductSlider.css';
import { IMAGE_URL } from '../../config';
import computerImg from '../../assets/images/computer.png';
import mobileImg from '../../assets/images/mobile.png';
import apple from '../../assets/images/apple.svg';
import googlePlay from '../../assets/images/google-play.svg';
import strings from '../../strings';
const ProductSlider = (props) => {
    return (
        <div className="card-wrapper"> 
            <div className="card">
                {/* card image */}
                <div className="card-image" >
                    <img src={IMAGE_URL+props.image} alt={props.name}/>
                </div>
                {/* card overlay */}
{/* 2 nested conditions 
1- first check if it's web or app 
    categogry === 1 => app // category == 2 => web 
2- second check if it's ios or android or both 
*/}                
                <div className="card-overlay">
                    { (props.category_id === 1)? 
                        <div className="web">
                            <img src={computerImg} alt="computer" className="mb-3"/>
                            <a className="ProductBtn" href={props.link} target="_blank" rel="noreferrer">
                                {strings.product.web_visit} 
                            </a>
                        </div> 
                    :   
                    <div className="app"> 
                        <img src={mobileImg} alt="mobile" className="mb-2"/>
                        {props.app_store_link &&   /* app play */             
                            <a className="ProductBtn AppBtn mb-2" href={props.app_store_link} target="_blank" rel="noreferrer">
                                <img src={apple} alt="apple" width="20px" className="mr-2"/>
                                <div>
                                    <p>{strings.product.apple_store}</p>
                                </div>
                            </a>
                        }

                        {props.google_play_link && /* google play */
                            <a className="ProductBtn AppBtn" href={props.google_play_link} target="_blank" rel="noreferrer">
                                <img src={googlePlay} alt="apple" width="20px" className="mr-2"/>
                                <div>
                                    <p>{strings.product.google_store}</p>
                                </div>
                            </a>
                        }
                    </div>  
                        
                    }
                </div>
                {/* name */}
                <div className="name">
                    <h3>{props.name}</h3>
                </div>
            </div>
        </div>
    );
}
export default ProductSlider;