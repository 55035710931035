import * as ActionTypes from '../actions/types';

const INIT_STATE = {
    aboutInfo: null
};

const aboutReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_ABOUT_INFO:
            return {...state, aboutInfo: action.data};
        default:
            return state;
    }
};
export default aboutReducer;