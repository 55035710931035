import React, { Component } from 'react';
import img from '../assets/images/grey-bg.png'
import Title from '../components/shared/Title/Title';
import {connect} from 'react-redux';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';
import * as actions from '../actions/index';
import strings from '../strings';
import TeamSlider from '../components/TeamSlider/TeamSlider';

class Team extends Component {
    componentDidMount(){
        this.props.ongetTeam();
    }
    render(){
        const params = {
            effect: 'coverflow',
            grabCursor: true,
            centeredSlides: true,
            slidesPerView: 'auto',
            loop: true,
            autoplay: {
                delay: 4000,
                disableOnInteraction: false
            },
            coverflowEffect: {
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true
            }
        }
        let team;
        if(this.props.team){
            team = this.props.team.data.map((member)=> {
                return <div key={member.id}>
                    <TeamSlider
                        key={member.id} 
                        image={member.image}
                        name = {member.name}
                        role = {member.role}/>                            
                </div>
            })
        } 

        return(
            <div id="team" style={{backgroundColor:'#ECEAF4'}}>
                <div className="container-fluid" >
                    <Title img={img} title={strings.heading.team} team/>
                    <div className="section-dimension">
                        <div className="container">
                        <Swiper {...params}>
                            {team}
                        </Swiper>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return{
        team: state.team.team
    };
    }
const mapDispatchToProps = dispatch => {
    return {
        ongetTeam: () => dispatch(actions.getTeam()), 
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Team);