import * as ActionTypes from '../actions/types';
const INIT_STATE = {
    clients: null
};

const clientsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_CLIENTS:
            return {...state, clients: action.data.data};
        default:
            return state;
    }
};
export default clientsReducer;