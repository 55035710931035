import {CONTACT_INFO} from './types';
import {Backend} from '../services/Backend';
export const contactInfo = (contactData) => {   
    return async (dispatch) => {
        await Backend.contactInfo(contactData).then(response => {
            dispatch({
                type: CONTACT_INFO
            });
        });
    };
};

