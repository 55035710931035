import React from 'react';
import './NavigationItems.css';
import { Link} from "react-scroll";
import strings from './../../../strings';
const NavigationItems = (props) => 
(
    <ul className="NavigationItems">
        <li className="NavigationItem">
            <Link 
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={-500}
                duration={500}
                onClick={props.closed}>
                {strings.heading.home}
            </Link>
        </li>
        <li className="NavigationItem">
            <Link          
                activeClass="active"
                to="about-us"
                spy={true}
                smooth={true}
                offset={-160}
                duration={500}
                onClick={props.closed}>
                {strings.heading.about}
            </Link>
        </li>
        <li className="NavigationItem">
            <Link 
                activeClass="active"
                to="products"
                spy={true}
                smooth={true}
                offset={-160}
                duration={500}
                onClick={props.closed}>
                {strings.heading.products}
            </Link>
        </li>
        <li className="NavigationItem">
            <Link 
                activeClass="active"
                to="services"
                spy={true}
                smooth={true}
                offset={-160}
                duration={500}
                onClick={props.closed}>
                {strings.heading.services}
            </Link>
        </li>
        <li className="NavigationItem">
            <Link 
                activeClass="active"
                to="clients"
                spy={true}
                smooth={true}
                offset={-160}
                duration={500}
                onClick={props.closed}>
                {strings.heading.clients}
            </Link>
        </li>
        <li className="NavigationItem">
            <Link 
                activeClass="active"
                to="team"
                spy={true}
                smooth={true}
                offset={-160}
                duration={500}
                onClick={props.closed}>
                {strings.heading.team}
            </Link>
        </li>
        <li className="NavigationItem">
            <Link 
                activeClass="active"
                to="contact-us"
                spy={true}
                smooth={true}
                offset={-160}
                duration={500}
                onClick={props.closed}>
            {strings.heading.contact}
            </Link>
        </li>
    </ul> 
);
export default NavigationItems ;