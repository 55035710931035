import React from 'react';
import './Sidedrawer.css';
import { Link} from "react-scroll";
import appLogo from '../../../assets/images/logo.png'
import NavigationItems from '../NavigationItems/NavigationItems';
import Backdrop from '../../shared/Backdrop/Backdrop';
import LangBtn from '../LangBtn/LangBtn';
const sidedrawer = (props) => {
let attachedClasses = ["Sidedrawer", "Close"];
if (props.show) {
    attachedClasses = ["Sidedrawer", "Open"];
}
return(
    <>
    <Backdrop show={props.show} closed={props.closed}/>
    <div className={attachedClasses.join(' ')} onClick={props.closed}> {/* close the drawer whenever we click on any nav item*/}
        <Link className="Logo" smooth to="home">
        <div className="sidedrawerLogo" onClick={props.closed}>
            <img src={appLogo} alt='true app'/>
        </div>
        </Link>
        <div>
        <nav> 
            <NavigationItems closed={props.closed}/>
        </nav>
        <LangBtn clicked={props.translate}/>
        </div>
    </div> 
    </>
)
};
export default sidedrawer;