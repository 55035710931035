import React, {useState} from 'react';
import './ToTopBtn.css';
import arrow from '../../assets/images/arrow-up.png';
const ToTopBtn = () => {
    const [showScroll, setShowScroll] = useState(false)

    const checkScrollTop = () => {
        if (!showScroll && window.pageYOffset > 90){
        setShowScroll(true)
        } else if (showScroll && window.pageYOffset <= 90){
        setShowScroll(false)
        }
    };

    const scrollTop = () =>{
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    window.addEventListener('scroll', checkScrollTop)
    return (
        <img 
            className="scrollTop" 
            onClick={scrollTop} 
            style={{ display: showScroll ? 'flex' : 'none'}} 
            src={arrow} alt="arrow up"/>
    );
    }

export default ToTopBtn;

