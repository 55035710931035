import React, { Component } from 'react';
import './Layout.css';
import Toolbar from '../Toolbar/Toolbar';
import Sidedrawer from '../Sidedrawer/Sidedrawer';
class Layout extends Component {
	state = {
		showSidedrawer: false, // show & hide sidedrawer 
	}
	
	/* ------ methods for sidedrawer -------*/
	sidedrawerClosedHandler = () => {
		this.setState({showSidedrawer:false})
	}
	sideDrawerTogglededHandler = () => {
			this.setState((prevState) => {
			return {showSidedrawer: !prevState.showSidedrawer};
		});
	}

    translateHandler = (langCode) => {
    // store the selected language in the local storage 
        localStorage.setItem('lang', langCode);
    // now we need to reload the page 
        window.location.reload()
	}
		
	render(){
		const lang = localStorage.getItem('lang') || 'en';
		return (
		<>
			<Toolbar 
				drawerToggleClicked={this.sideDrawerTogglededHandler}
				translate = {() => this.translateHandler(lang === "en"? "ar": "en")}/>       
			<Sidedrawer 
				show ={this.state.showSidedrawer} 
				closed={this.sidedrawerClosedHandler}
				translate = {() => this.translateHandler(lang === "en"? "ar": "en")}/>			
			<main className ="Content">
			{this.props.children}
			</main>
		</>
		);
	}
}
export default Layout;