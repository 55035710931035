import './ClientsSlider.css';
import { IMAGE_URL } from '../../config';
const SliderWrapper = (props) => {
    return (
        <div className="card-wrapper"> 
            <div className="card">
                {/* card image */}
                <div className="card-image" >
                    <img src={IMAGE_URL+props.img} alt={props.name}/>
                </div>
            </div>
        </div>
    );
}
export default SliderWrapper;