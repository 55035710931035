import './TeamSlider.css';
import { IMAGE_URL } from '../../config';
import default_img from '../../assets/images/default-image.png';
const TeamSlider = (props) => {
    return (
        <div>
            <div className="img-box">
                <img src={props.image? `${IMAGE_URL}${props.image}`: `${default_img}`} alt={props.name}/>
            </div>
            <div className="details">
                <h3>{props.name} <br></br><span>{props.role}</span></h3>
            </div>
        </div>
    );
}
export default TeamSlider;