import React, { Component } from 'react';
import Title from '../../components/shared/Title/Title';
import img from '../../assets/images/purple-bg.png';
import ContactInfo from './ContactInfo/ContactInfo';
import Form from './ContactForm/Form';
import strings from '../../strings';
class ContactUs extends Component {
    render() {
        return (
            <div id="contact-us" style={{backgroundColor: '#4B3494'}}>
                <div className="container-fluid">
                    <Title img={img} title={strings.heading.contact} contact/>
                    <div className="section-dimension padding-x">
                        <div className="row">
                            <ContactInfo/>
                            <Form/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default ContactUs;
