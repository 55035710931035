import './LangBtn.css';
import strings from '../../../strings';
const LangBtn = (props) => {
    return (
        <button 
            className="langBtn Desktop-only" 
            onClick={props.clicked}>
            {strings.langBtn.lang}
        </button>
    );
}
export default LangBtn;