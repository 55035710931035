import React, { Component } from 'react';
import './ErrorBoundary.css';
import img from '../../assets/images/error-warning.png';
import strings from '../../strings';
class ErrorBoundary extends Component {
    state = { 
        hasError: false,
        error: null,
        errorInfo: null
    };
    componentDidCatch(error, info){
        this.setState({
            hasError: true,
            error,
            info
        })
    }
    render() {
        if(this.state.hasError){
            return (
                <div className="container">
                    <div className="error-boundary">
                        <div className="img-container">
                            <img src={img} alt="warning error"/>
                        </div>
                        <h1>{strings.error_boundary}</h1>
                    </div>
                </div>
            )
        }
        return this.props.children;
    }
}

export default ErrorBoundary;