import {combineReducers} from 'redux';
import aboutInfoReducer from './aboutReducer';
import productsReducer from './productsReducer';
import clientsReducer from './clientsReducer';
import servicesReducer from './servicesReducer';
import teamReducer from './teamReducer';
export default combineReducers({
    about: aboutInfoReducer,
    products: productsReducer,
    services: servicesReducer,
    clients: clientsReducer,
    team: teamReducer,
});