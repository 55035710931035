import React, { Component } from 'react';
import HeroBanner from '../components/HeroBanner/HeroBanner';
class Home extends Component {
    render() {
        return (
            <div id="home">
                <HeroBanner/>
            </div>
        );
    }
}
export default Home;