import './Info.css';
import phone from '../../assets/images/phone.png';
import email from '../../assets/images/email.png';
import facebookIcon from '../../assets/images/facebook.png';
import strings from '../../strings';
const Info = (props) => {
    const {emial, mobile_number, facebook} = props.contactInfo;
    return (
        <div>
            {/* contact info */}
            <ul className="Info">
                <li>
                    <img src={phone} alt="phone"/>
                    <span>{mobile_number}</span>
                </li>
                {/* <li>
                    <img src={email} alt="email"/>
                    <span>{emial}</span>
                </li> */}
                <li>
                    <a href={facebook} target="_blank" rel="noreferrer" className="d-flex justify-content-center align-items-center">
                        <img src={facebookIcon} alt="facebook"/>
                        <span>{strings.contact.facebook}</span>
                    </a>
                </li>
            </ul>    
        </div>
    );
}
export default Info;