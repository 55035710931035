import * as ActionTypes from '../actions/types';

const INIT_STATE = {
    team: null
};

const teamReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_TEAM:
            return {...state, team: action.data.data};
        default:
            return state;
    }
};
export default teamReducer;