import React, { Component } from 'react';
import img from '../assets/images/white-bg.png'
import Title from '../components/shared/Title/Title';
import {connect} from 'react-redux';
import * as actions from '../actions/index';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ClientsSlider from '../components/ClientsSlider/ClientsSlider';
import strings from '../strings';
class Clients extends Component {
    componentDidMount(){
        this.props.ongetClients();
    }

    render() {
        let settings = {
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            pauseOnFocus: false,
            pauseOnHover: false,
            speed: 4000,
            autoplaySpeed: 4000,
            cssEase: "linear",
            responsive: [
                {
                breakpoint: 1700,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                    }
                },
                {
                breakpoint: 1300,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };       
        let clients; 
        if(this.props.clients){
            if(this.props.clients){
                clients = this.props.clients.data.map(client=> {
                    return <ClientsSlider
                        key={client.id} 
                        img={client.image} 
                        name={client.name} 
                        description={client.description}/>
                })
            }
        }
        return (
            <div id="clients" style={{backgroundColor: "#fff"}}>
                <div className="container-fluid">
                    <Title img={img} title={strings.heading.clients} clients/>
                    <div className="px-md-5 section-dimension">
                        <Slider {...settings}>
                            {clients}
                        </Slider>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return{
        clients: state.clients.clients
    };
    }
const mapDispatchToProps = dispatch => {
    return {
        ongetClients: () => dispatch(actions.getClients()), 
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Clients);