import {GET_CLIENTS} from './types';
import {Backend} from '../services/Backend';
export const getClients = (pageNumber) => {
    return async (dispatch) => {
        const name = `/clients`;
        await Backend.getPage(name, pageNumber).then(response => {
            dispatch({
                type: GET_CLIENTS,
                data: response.data
            });
        });
    };
};