import React, { Component } from 'react';
import  img from '../assets/images/white-bg.png';
import Title from '../components/shared/Title/Title';
import {connect} from 'react-redux';
import * as actions from '../actions/index';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import strings from '../strings';
import ProductSlider from '../components/ProductSlider/ProductsSlider';
class Products extends Component {
    componentDidMount(){
        this.props.ongetProducts();
    }
    render() {
        let settings = {
            infinite: true,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                breakpoint: 1700,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        initialSlide: 3
                    }
                },
                {
                breakpoint: 1300,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        initialSlide: 2
                    }
                },
                {
                breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        let products; 
        if(this.props.products){
            products = this.props.products.data.map((product)=> {
                return <ProductSlider
                    key={product.id} 
                    image={product.image}
                    name = {product.name}
                    category_id ={product.category_id}
                    app_store_link={product.app_store_link}
                    google_play_link={product.google_play_link}
                    link={this.props.link}/>
            })
        }
        return (
            <div id="products" style={{backgroundColor: '#fff'}}>
                <div className="container-fluid">
                    <Title img={img} title={strings.heading.products} products/>
                    <div className="slider-container section-dimension">
                        <Slider {...settings}>
                            {products}
                        </Slider>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return{
        products: state.products.products
    };
    }
const mapDispatchToProps = dispatch => {
    return {
        ongetProducts: () => dispatch(actions.getProducts()), 
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Products);