import React, { Component } from 'react';
import {BrowserRouter} from 'react-router-dom';
import Home from './../pages/Home';
import AboutUs from '../pages/AboutUs/AboutUs';
import Products from './../pages/Products';
import Services from './../pages/Services';
import Clients from './../pages/Clients';
import ContactUs from '../pages/ContactUs/ContactUs';
import Team from './../pages/Team';
import Layout from '../components/Navigation/Layout/Layout';
import Footer from '../pages/Footer/Footer';
import {connect} from 'react-redux';
import * as actions from '../actions/index';
import Spinner from '../components/shared/Spinner/Spinner';
import ToTopBtn from '../components/ToTopBtn/ToTopBtn';
class router extends Component {
    componentDidMount(){
        this.props.ongetAboutInfo();
        this.props.ongetProducts();
        this.props.ongetClients();
        this.props.ongetServices();
        this.props.ongetTeam();
    }
    render() {
        return (
            <>
            {(this.props.about && this.props.products && this.props.services && this.props.clients && this.props.team)? 
                <BrowserRouter>
                    <Layout>
                        <Home/>
                        <AboutUs/>
                        <Products/>
                        <Services/>
                        <Clients/>
                        <Team/>
                        <ContactUs/>
                        <Footer/> 
                        <ToTopBtn />
                    </Layout>    
                </BrowserRouter>
            :
            <Spinner/>
            }
            </>
        );
    }
}
const mapStateToProps = state => {
    return{
        about: state.about.aboutInfo,
        products: state.products.products,
        services: state.services.services,
        clients: state.clients.clients,
        team: state.team.team,
    };
}
const mapDispatchToProps = dispatch => {
    return {
        ongetAboutInfo: () => dispatch(actions.getAboutInfo()), 
        ongetProducts: () => dispatch(actions.getProducts()), 
        ongetClients: () => dispatch(actions.getClients()), 
        ongetServices: () => dispatch(actions.getServices()), 
        ongetTeam: () => dispatch(actions.getTeam()), 
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(router);
