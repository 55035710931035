import React, { Component } from 'react';
import img from '../assets/images/purple-bg.png'
import Title from '../components/shared/Title/Title';
import {connect} from 'react-redux';
import * as actions from '../actions/index';
import Service from '../components/Service/Service';
import strings from '../strings';
class Services extends Component {
    componentDidMount(){
        this.props.ongetServices();
    }

    render() {   
        let services; 
        if(this.props.services){
            services = this.props.services.data.map(service => {
                return <Service
                    key={service.id} 
                    image={service.image} 
                    name={service.name} 
                    description={service.description}/>
            })
        }
        return (
            <div id="services" className="" style={{backgroundColor: "#4b3494"}}>
                <div className="container-fluid">
                    <Title img={img} title={strings.heading.services} services/>
                    <div className="section-dimension">
                        <div className="container">
                            <div className="row">
                                {services}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return{
        services: state.services.services
    };
    }
const mapDispatchToProps = dispatch => {
    return {
        ongetServices: () => dispatch(actions.getServices()), 
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Services);