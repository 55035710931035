import * as ActionTypes from '../actions/types';
const INIT_STATE = {
    products: null
};

const productsReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case ActionTypes.GET_PRODUCTS:
            return {...state, products: action.data.data};
        default:
            return state;
    }
};
export default productsReducer;