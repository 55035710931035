import {GET_PRODUCTS} from './types';
import {Backend} from '../services/Backend';

export const getProducts = (pageNumber) => {
    return async (dispatch) => {
        const name = `/products`;
        await Backend.getPage(name, pageNumber).then(response => {
            dispatch({
                type: GET_PRODUCTS,
                data: response.data
            });
        });
    };
};
