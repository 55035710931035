import {GET_ABOUT_INFO} from './types';
import {Backend} from '../services/Backend';

export const getAboutInfo = () => {
    return async (dispatch) => {
        await Backend.getAboutInfo().then(response => {
            dispatch({
                type: GET_ABOUT_INFO,
                data: response.data
            });
        });
    };
};
