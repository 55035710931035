import React from 'react';
import { Link } from "react-scroll";
import './Toolbar.css';
import appLogo from './../../../assets/images/logo.png';
import NavigationItems from '../NavigationItems/NavigationItems';
import DrawerToggle from '../DrawerToggle/DrawerToggle';
import LangBtn from '../LangBtn/LangBtn';
const toolbar = (props) => (
    <header className="Toolbar">
		<Link className="Logo" smooth to="home">
			<div className="Logo">
			<img src={appLogo} alt='true app'/>
			</div>
		</Link>
		<DrawerToggle clicked={props.drawerToggleClicked}/>
		<div className="Wrapper DesktopOnly">
			<LangBtn clicked={props.translate}/> 
			<nav>
			<NavigationItems/>
			</nav>
		</div>
    </header>
);
export default toolbar;